import { WorkflowStatesConstant } from "@/common/constant/WorkflowStatesConstant";
import { $inj, $injByInterface } from "@/common/decorators/depinject";
import { GAnalyticsFactory, WorkflowFactory } from "@/common/services/services.module";
import type IWorkspaceMetadataStore from "@/common/services/Workspace/IWorkspaceMetadataStore";
import { useRoute } from "vue-router";
import type IRouterService from "@/common/services/utils/IRouterService";

/*
To handle http errors. This instance is injected in common/httphandler
*/
export class ErrorHandler {
  //deprecated. we have to let interceptor handle it and route to above function
  public static handle(error: any) {
    const routerService = $injByInterface<IRouterService>("IRouterService");
    const workflowFactory = $inj(WorkflowFactory);
    workflowFactory.setEndPreloadingStatus();
    routerService.push({ name: WorkflowStatesConstant.ERROR.STATE });
  }

  public static async handleNavigationErrorForWorkspace(workspaceUUID: string, trackerUUID: string) {
    const routerService = $injByInterface<IRouterService>("IRouterService");
    const workspaceMetadataStore = $injByInterface<IWorkspaceMetadataStore>("IWorkspaceMetadataStore");
    const gAnalyticsFactory = $inj(GAnalyticsFactory);
    const workflowFactory = $inj(WorkflowFactory);
    workflowFactory.setEndPreloadingStatus();
    // gAnalytics is alredy initialized in WorkflowState. Hence not initialized here.
    switch (workspaceMetadataStore.onErrorRedirectTo) {
      case WorkflowStatesConstant.CONTINUE.STATE:
        // below condition and router's go method have been used to handle session expiry while on continue module
        if (routerService.currentRoute.value.name === WorkflowStatesConstant.CONTINUE.STATE) {
          routerService.go(0);
        } else {
          routerService.push({
            name: WorkflowStatesConstant.CONTINUE.STATE,
            params: { workspaceUUID: workspaceUUID },
            query: { trackerUUID: trackerUUID },
            replace: true
          });
        }
        break;
      case WorkflowStatesConstant.ERROR.STATE:
        routerService.push({ name: WorkflowStatesConstant.ERROR.STATE, replace: true });
        gAnalyticsFactory.sendWorkflowEvent({ state: WorkflowStatesConstant.ERROR.STATE });
        break;
      case "":
        break;
      default:
        routerService.push({ name: WorkflowStatesConstant.ERROR.STATE, replace: true });
        gAnalyticsFactory.sendWorkflowEvent({ state: WorkflowStatesConstant.ERROR.STATE });
        break;
    }
  }

  public static async handleGlobalError(err: any) {
    const route = useRoute();
    const workspaceUUID = route.params.workspaceUUID as string;
    const trackerUUID = route.query.trackerUUID as string;
    if (workspaceUUID && trackerUUID) {
      ErrorHandler.handleNavigationErrorForWorkspace(workspaceUUID, trackerUUID);
    } else {
      ErrorHandler.handle(err);
    }
  }
}
