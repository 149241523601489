import { inject, injectable } from "inversify";
import { QueryPropertiesService } from "@/open/app/factories/config/queryProperties.service";
import { QueryPropertyKey } from "@/open/app/factories/config/queryProperties.enum";
import WorkspacePromoCodeService from "@/common/services/Workspace/WorkspacePromoCodeService";
import WorkspaceReferralService from "@/common/services/Workspace/WorkspaceReferralService";

@injectable()
export default class QueryParameterCollectionService {

  constructor(
    @inject(QueryPropertiesService) private queryPropertiesService: QueryPropertiesService,
    @inject(WorkspacePromoCodeService) private workspacePromoCodeService: WorkspacePromoCodeService,
    @inject(WorkspaceReferralService) private workspaceReferralService: WorkspaceReferralService,
  ) {
  }

  /**
   *   This will collect certain statically defined query parameters that are used for tracking customer interactions
   *   cross domain like referrals and promotional codes.
   */
  saveAllForWorkspace(workspaceUUID: string) {
    this.#savePromotionalCodes(workspaceUUID);
    this.#saveWorkspaceReferral(workspaceUUID);
  }

  /**
   *
   * A higher order function that accepts a raw query parameter, splits it by the provided delimiter, and then calls
   * the provided function for every result. If the parameter is falsey, this acts as a noop.
   *
   * @param queryParameter
   * @param fn
   * @param delimiter
   * @private
   */
  #forEachParamIn(queryParameter: string, fn: (it: string) => void, delimiter = ",") {
    if (queryParameter) {
      const params = queryParameter.split(delimiter);
      params.forEach((param) => fn(param));
    }
  }

  #savePromotionalCodes(workspaceUUID: string) {

    const queryParam = this.queryPropertiesService.get(QueryPropertyKey.PROMO);

    this.#forEachParamIn(queryParam, (promoCode) => {
      this.workspacePromoCodeService.createPromoCode(workspaceUUID, {
        workspaceUuid: workspaceUUID,
        code: promoCode
      }).catch((err) => console.error(`An error occurred while trying to collect workspace promo code ${promoCode}`, err));
    })

  }

  #saveWorkspaceReferral(workspaceUUID: string) {

    const queryParam = this.queryPropertiesService.get(QueryPropertyKey.REF);

    this.#forEachParamIn(queryParam, (referral) => {
      this.workspaceReferralService.createRef(workspaceUUID, {
        workspaceUuid: workspaceUUID,
        referral: referral
      })
    })

  }

}