import { defineStore } from "pinia";
import type LeafAddress from "@/common/data/LeafAddress";

export interface AddressItem {
    value: LeafAddress,
    label: string,
    index: number
}
export interface IFundingStore {
    selectedAddress?: AddressItem,
    addressList: Array<LeafAddress>
}
export const FundingStore = defineStore({
    id: "FundingStore",
    state: (): IFundingStore => ({
        selectedAddress: undefined,
        addressList: [] as Array<LeafAddress>
    })
});