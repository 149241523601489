enum QueryPropertyKey {
    // used for custom workflow entry points
    WORKFLOW = 'w',
    // whether or not we should hide the open toolbar ( for using our app in an iframe )
    HEADLESS = 'isHeadless',
    // promo codes to be created via workspacePromoCodeService
    PROMO = 'promo',
    REF = 'ref',
    CODE = "code"
};
const QueryPropertiesConst = <object>QueryPropertyKey;
const QueryPropertiesSelector = 'QUERY_PROPS';

export { QueryPropertyKey, QueryPropertiesConst, QueryPropertiesSelector };
// temp shim for posting enums to AngularJS as we can't import raw enums.

