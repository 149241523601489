import DEPENDENCYTYPES from "@/common/dependency.types";
import ERROR from "@/common/enums/errorEnum";
import { WorkflowStatesConstant } from "@/common/constant/WorkflowStatesConstant";
import type IWorkspaceMetadataStore from "@/common/services/Workspace/IWorkspaceMetadataStore";
import { inject, injectable } from "inversify";
import { BroadcastService, ErrorFactory, NotificationFactory } from "@/common/services/services.module";
import type IHttpErrorHandler from "@/common/services/connect/IHttpErrorHandler";
import type { AxiosResponse } from "axios";
import type CacheableAxiosRequestConfig from "@/common/services/connect/CacheableAxiosRequestConfig";

/**This OpenHttpErrorHandler is implementation of orignal angular.js's OpenResponseInterceptor */
@injectable()
class OpenHttpErrorHandler implements IHttpErrorHandler {
    constructor(
        @inject(DEPENDENCYTYPES.IWorkspaceMetadataStore) private workspaceMetadataStore: IWorkspaceMetadataStore,
        @inject(ErrorFactory) private errorFactory: ErrorFactory,
        @inject(BroadcastService) private broadcastService: BroadcastService,
        @inject(NotificationFactory) private notificationFactory: NotificationFactory
    ) { }
    handle(errResponse: AxiosResponse): void {
        if (errResponse && errResponse.data && errResponse.data.errors) {
            this.handleKnownErrors(errResponse);
        } else {
            this.workspaceMetadataStore.onErrorRedirectTo = WorkflowStatesConstant.ERROR.STATE;
        }
    }
    private handleKnownErrors(errResponse: AxiosResponse): void {
        const errors = errResponse.data.errors.reduce((col: { add: (arg0: any) => void }, error: { code: any }) => {
            col.add(error.code);
            return col;
        }, new Set());
        
        const config = errResponse.config as CacheableAxiosRequestConfig;
        const isValidationRequest = config && config.isValidationRequest;

        if (errors.has(ERROR.EOD_ERROR)) {
            this.workspaceMetadataStore.onErrorRedirectTo = WorkflowStatesConstant.ERROR.STATE;
            this.errorFactory.displayEodError();
        } else if (errors.has(ERROR.OPEN_ABORT)) {
            this.workspaceMetadataStore.onErrorRedirectTo = WorkflowStatesConstant.ERROR.STATE;
            this.errorFactory.displayError(errResponse);
        } else if (errors.has(ERROR.OPEN_UNAUTHORIZED) || errors.has(ERROR.OPEN_EXPIRED)) {
            this.workspaceMetadataStore.onErrorRedirectTo = WorkflowStatesConstant.CONTINUE.STATE;
        } else if (
            isValidationRequest ||
            errResponse.status === 400 ||
            errors.has(ERROR.RULE_COMPONENT_MESSAGE) ||
            errors.has(ERROR.RULE_VALIDATOR_MESSAGE) ||
            errors.has(ERROR.DISCLOSURE_TO_EMAIL_INVALID) ||
            errors.has(ERROR.GENERAL_ERROR) ||
            errors.has(ERROR.APPLICANT_DEMOGRAPHIC_RACE) ||
            errors.has(ERROR.CHALLENGE_VALIDATION_FAILED) ||
            errors.has(ERROR.WORKSPACE_IS_CLOSED) ||
            errors.has(ERROR.WORKSPACE_NOT_FOUND) ||
            errors.has(ERROR.REQUEST_FAILED) ||
            errors.has(ERROR.BRANCH_MAPPING_NOT_FOUND) ||
            errors.has(ERROR.NCR_INVALID_PASSWORD) ||
            errors.has(ERROR.NCR_PROPERTIES_NOT_CONFIGURED) ||
            errors.has(ERROR.JXCHANGE_ERROR) ||
            errors.has(ERROR.APPLICANT_DOES_EXIST_IN_CORE) ||
            errors.has(ERROR.CREATE_APPLICANT_IN_CORE_FAILED) ||
            errors.has(ERROR.IMAGE_FAILED_PROCESS) ||
            errors.has(ERROR.APPLICANT_TYPE_NOT_PERSON) ||
            errors.has(ERROR.REQUEST_NOT_PROCESSED) ||
            errors.has(ERROR.DATA_INTEGRITY_VIOLATION)
        ) {
            this.workspaceMetadataStore.onErrorRedirectTo = "";
            this.errorFactory.setErrorMessage(errResponse);
            if (errResponse.data && Array.isArray(errResponse.data.errors) && errResponse.data.errors.length > 0) {
                this.notificationFactory.showData(errResponse.data);
            }
        } else {
            this.errorFactory.setErrorMessage(errResponse);
            this.workspaceMetadataStore.onErrorRedirectTo = WorkflowStatesConstant.ERROR.STATE;
        }
    }
}

export default OpenHttpErrorHandler;