import DEPENDENCYTYPES from '@/common/dependency.types';
import type IRouterService from '@/common/services/utils/IRouterService';
import { inject, injectable } from 'inversify';
import { QueryPropertyKey } from './queryProperties.enum';


@injectable()
export class QueryPropertiesService {

    static selector = 'QueryPropertiesService';
    private _properties: { [key: string]: string } = {};

    constructor(
        @inject(DEPENDENCYTYPES.IRouterService) private routerService: IRouterService
    ) {
    }

    init(): void {
        this._properties = {};
        Object.keys(QueryPropertyKey).forEach((key) => {
            const keyAsQueryPropertyKey = key as keyof typeof QueryPropertyKey;
            const queryPropertyValue = QueryPropertyKey[keyAsQueryPropertyKey];
            this._setPropertyValue(key, queryPropertyValue)
        });
    }

    get(key: QueryPropertyKey): string {
        this._ensureInitialized();
        return this._properties[key];
    }

    getBool(key: QueryPropertyKey): boolean {
        this._ensureInitialized();
        if (['true', 'Y'].indexOf(this._properties[key]) !== -1) {
            return true;
        } else if (['false', 'N'].indexOf(this._properties[key]) !== -1) {
            return false;
        } else {
            return false;
        }
    }

    private _ensureInitialized(): void {
        if (!this._properties) {
            throw new Error('QueryPropertiesService must be initialized with QueryPropertiesService.init() before usage.');
        }
    }

    private _setPropertyValue(key: string, value: string): void {
        this._properties[value] = this.routerService.getQuery(value) || '';
    }
}
