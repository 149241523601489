import { defineStore } from "pinia";
import type {Applicant} from "@/common/components/bltQuestionnaire/cipTypes";

export interface ICipStore {
    applicants: Array<Applicant>
}
export const CipStore = defineStore({
    id: "CipStore",
    state: (): ICipStore => ({
        applicants: [] as Array<Applicant>
    })
});