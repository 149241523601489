<script lang="ts">
import { defineComponent } from "vue";
import PhoneNumberFilter from "@/common/services/filters/phoneNumberFilter";
import { $inj } from "@/common/decorators/depinject";

export default defineComponent({
  name: "footerComponent",
  data() {
    return {
      currentYear: 0,
      institution: {
        CUFooter: "",
        privacyPolicyUrl: "",
        footerText: "",
        displayName: "",
        phoneNumber: "",
        footerInsuranceAgency: ""
      },
      insuranceAgency: {
        CUFooter: ""
      }
    };
  },
  setup() {
    const phoneNumberFilter = $inj(PhoneNumberFilter);
    return {
      phoneNumberFilter
    };
  },
  created() {
    this.currentYear = new Date().getFullYear();
    this.institution = (window as any).institutionConfig;
  },
  computed: {
    privacyPolicyText() {
      return this.institution.privacyPolicyUrl ? "Privacy policy" : "User agreement";
    },

    privacyPolicyUrl() {
      return this.institution.privacyPolicyUrl || "/eula.html";
    },

    footerText() {
      return (this.institution.footerText || "Equal Housing Lender").trim();
    },

    phoneNumberFormat() {
      const phoneNumber = this.institution.phoneNumber.replace(/\D/g,'');
      return this.phoneNumberFilter.phonenumber(phoneNumber);
    },

    insuranceAgencyText() {
      return (
        this.institution.footerInsuranceAgency ||
        (this.institution.CUFooter ? "Federally Insured by NCUA" : "Member FDIC")
      ).trim();
    }
  }
});
</script>

<template>
  <section class="footerSection">
    © {{ currentYear }} {{ institution.displayName }} <span>•</span> {{ phoneNumberFormat }}
    <span class="end-of-line">•</span>
    <a :href="privacyPolicyUrl">
      {{ privacyPolicyText }}
    </a>
    <span class="end-of-line">•</span>
    <br />
    {{ insuranceAgencyText }}
    <span v-if="footerText" class="end-of-line">•</span>

    <svg v-if="footerText" width="16" height="11" viewBox="0 0 10 7" class="ehl-icon" aria-hidden="true">
      <path
        d="M4.96.222L.337 2.588v.962l.511.007v3.448h8.186L9.03 3.55h.55v-.962L4.959.222zm3.163 5.872H1.76V3.028l3.2-1.65 3.163 1.65v3.066zm-4.677-2.26h2.999v-.828H3.446v.828zm0 1.489h2.985v-.828H3.446v.828z"
      ></path>
    </svg>
    {{ footerText }}
  </section>
</template>

<style scoped>
span {
  margin: 0px 7px;
}
.ehl-icon {
  display: inline-block;
  fill: var(--footer-text-color);
}
a {
  /* display: block; */
  text-decoration: none;
  color: var(--jha-text-theme) !important;
  cursor: pointer;
  width: auto !important;
  display: inline-block;
}

a:hover {
  color: var(--inline-button-text-hover-color);
  text-decoration: underline;
}

a:active {
  color: var(--inline-button-text-active-color);
  text-decoration: underline;
}

br {
  display: none;
}
@media (max-width: 490px) {
  :host {
    padding: 20px;
  }
  br {
    display: block;
  }
  .footerSection {
    padding: 8px 10px;
  }
  /* hide • at end of line when <br> is present */
  .end-of-line {
    display: none;
  }
}
</style>
