import { defineStore } from "pinia";
import {
  ApplicantPersonFactory,
  ApplicantBusinessFactory,
  ApplicantFactory,
  ApplicantBusinessApplicantFactory,
  AccountApplicantDisclosureFactory,
  TrackerFactory,
  ApplicantAddressFactory,
  AlliedFundingFactory,
  ApplicantDemographicFactory,
  WorkspaceFactory,
  WorkflowFactory,
  EnrollmentFactory,
  LookupFactory,
  ConstantFactory,
  ApplicantOCRFactory,
  ApplicantPhoneFactory,
  ApplicantSuggestionFactory,
  ApplicantIdentificationFactory,
  ApplicantEmailsFactory,
  ApplicantDocumentFactory,
  ApplicantImageFactory,
  WorkspaceStateFactory,
  ApplicantInviteFactory,
  CompleteQuestionnaireFactory,
  DisclosureFactory,
  LanguageFactory,
  UiImageFactory,
  PersonRelationshipsFactory,
  OAuthFactory,
  EnrollmentAccountFactory,
  EnrollmentStatusFactory,
  QuestionnaireFactory,
  EnrollmentFundingFactory,
  EnrollmentValidationFactory,
  ConfigService
} from "@/common/services/services.module";

import SsoRedirectFactory from "../states/sso-redirect/ssoRedirect.factory";
import { $inj, $injByInterface } from "@/common/decorators/depinject";
import WORKFLOW_TYPES from "@/common/enums/workflowTypesEnum";
import type IWorkspaceStore from "@/common/services/Workspace/IWorkspaceStore";

export const useE2eStore = defineStore({
  id: "e2e",
  state: (): CommonStoreState => ({
    getWorkspaceResponse: {},
    postWorkspaceResponse: {},
    setWorkspaceResponse: {},
    isClosedResponse: "",
    applicantBusinessResponse: {},
    applicantResponse: {},
    setApplicantResponse: {},
    getApplicantResponse: {},
    putApplicantResponse: {},
    postApplicantResponse: {},
    deleteApplicantResponse: {},
    saveApplicantResponse: {},
    createApplicantResponse: {},
    activeApplicantId: 0,
    disclosureListResponse: {},
    alliedFundingResponse: {},
    applicantDemographicResponse: {},
    trackerResponse: {},
    linkTrackerResponse: [],
    initTrackerResponse: [],
    ABAresponse: {},
    uiImageResponse: [],
    applicantAddressList: [],
    enrollmentResponse: {},
    getConstantResponse: {},
    getAllConstantResponse: {},
    workspaceStateResponse: {},
    workflowResponse: {},
    lookupDocumentsList: [],
    getOrPostEnrollementResponse: {},
    postAndNotifyEnrollementResponse: {},
    getAllEnrollementResponse: {},
    deleteEnrollementResponse: {},
    postEnrollementResponse: {},
    getEnrollementResponse: {},
    putEnrollmentResponse: {},
    getCoreHeaderResponse: {},
    getEnrollmentAccountsResponse: {},
    getEnrollmentApplicantsResponse: {},
    getEnrollmentValidationResponse: {},
    postApplicantOCRResponse: {},
    postApplicantPhoneResponse: {},
    deleteApplicantPhoneResponse: {},
    saveApplicantPhoneResponse: {},
    saveApplicantPhoneListResponse: {},
    putApplicantPhoneResponse: {},
    postApplicantPhoneListResponse: {},
    setPhoneTypeDescriptionResponse: {},
    setPhoneListTypeDescriptionResponse: {},
    checkEsignApplicantDisclosureFactoryResponse: {},
    validateEsignApplicantDisclosureFactoryResponse: {},
    requestEsignApplicantDisclosureFactoryResponse: {},
    printApplicantDisclosureFactoryResponse: {},
    postAllInitialApplicantDisclosureFactoryResponse: {},
    getAllInitialDisclosureStatusApplicantDisclosureFactoryResponse: {},
    getAllInitialDisclosureStatusResponse: {},
    getApplicantDisclosureFactoryResponse: {},
    putApplicantDisclosureFactoryResponse: {},
    unlockApplicantDisclosureFactoryResponse: {},
    resetApplicantDisclosureFactoryResponse: {},
    getApplicantDisclosurePackageResponse: {},
    getApplicantSuggestResponse: {},
    getApplicantIdentificationResponse: {},
    getAllInitialApplicantDisclosureFactoryResponse: {},
    applicantEmailResponse: {},
    applicantDocumentList: [],
    postOcrResponse: {},
    applicantInviteResponse: {},
    getCompleteQuestionnaireResponse: {},
    ssoRedirectResponse: {},
    enrollmentAccountResponse: {},
    enrollmentAccountListResponse: {},
    enrollmentStatusResponse: {},
    getPersonRelationshipsResponse: {},
    enrollmentFundingFactoryResponse: {},
    enrollmentValidationResponse: {},
    oauthResponse: {},
    questionnaireSaveResponse: {}
  }),
  getters: {},
  actions: {
    async FromBootstrap(documentCode: string) {
      return await $inj(DisclosureFactory).get(documentCode);
    },
    async getAllMessgaes() {
      await $inj(LanguageFactory).getAll();
    },

    async getMessageByCode(code: string, ignoreCache: boolean | undefined) {
      return await $inj(LanguageFactory).get(code, ignoreCache);
    },

    async configServiceBootstrap() {
      return await $inj(ConfigService).bootstrap();
    },
    async postWorkspace() {
      const workspaceFactory = $inj(WorkspaceFactory);
      this.postWorkspaceResponse = await workspaceFactory.postWorkspace({ workspaceType: WORKFLOW_TYPES.PERSON });
      const workspaceStore = $injByInterface<IWorkspaceStore>("IWorkspaceStore");
      workspaceStore.workspaceUUID = this.postWorkspaceResponse.workspaceUUID;
    },

    // Workspace Factory start
    async getWorkspace(uuid: any) {
      const workspaceFactory = $inj(WorkspaceFactory);
      this.getWorkspaceResponse = await workspaceFactory.getWorkspace(uuid);
    },
    async setStatus(workspace: any, status: any) {
      const workspaceFactory = $inj(WorkspaceFactory);
      this.setWorkspaceResponse = await workspaceFactory.setStatus(workspace, status);
    },
    async isClosed(workspace: any) {
      const workspaceFactory = $inj(WorkspaceFactory);
      this.isClosedResponse = await workspaceFactory.isClosed(workspace);
    },
    // Workspace Factory End

    // UI Image Factory Start
    async getUIImage(typeCode: string) {
      const uiImageFactory = $inj(UiImageFactory);
      this.uiImageResponse = await uiImageFactory.get(typeCode);
      return this.uiImageResponse;
    },

    /* async setUIImage(typeCode: string) {
      const uiImageFactory = $inj(UiImageFactory);
      this.uiImageResponse = await uiImageFactory.setFavicon(typeCode);
      return this.uiImageResponse;
    }, */
    // UI Image Factory End

    // Applicant Business Factory Start
    async getApplicantBusiness(workspaceUUID: string, applicantId: number) {
      const applicantBusinessFactory = $inj(ApplicantBusinessFactory);
      this.applicantBusinessResponse = await applicantBusinessFactory.get(workspaceUUID, applicantId);
    },
    // Applicant Business Factory End

    // Applicant Person Factory Start
    async createApplicant(workspaceUUID: string, applicantType: any) {
      const applicantFactory = $inj(ApplicantFactory);
      this.createApplicantResponse = await applicantFactory.post(workspaceUUID, applicantType);
    },

    async getApplicants(workspaceUUID: string, applicantType?: any) {
      const applicantFactory = $inj(ApplicantFactory);
      this.applicantResponse = await applicantFactory.getApplicants(workspaceUUID, applicantType);
    },

    // Applicant Person Factory Start
    async setApplicant(workspaceUUID: string, applicant: any) {
      const applicantPersonFactory = $inj(ApplicantPersonFactory);
      this.setApplicantResponse = await applicantPersonFactory.set(workspaceUUID, applicant);
    },

    async getApplicant(workspaceUUID: string, applicantId: number) {
      const applicantPersonFactory = $inj(ApplicantPersonFactory);
      this.getApplicantResponse = await applicantPersonFactory.get(workspaceUUID, applicantId);
    },
    async putApplicant(workspaceUUID: string, applicantId: number, person: any) {
      const applicantPersonFactory = $inj(ApplicantPersonFactory);
      this.putApplicantResponse = await applicantPersonFactory.put(workspaceUUID, applicantId, person);
    },
    async postApplicant(workspaceUUID: string, applicantId: number, person: any) {
      const applicantPersonFactory = $inj(ApplicantPersonFactory);
      this.postApplicantResponse = await applicantPersonFactory.post(workspaceUUID, applicantId, person);
    },
    async deleteApplicant(workspaceUUID: string, applicantId: number, personId: string) {
      const applicantPersonFactory = $inj(ApplicantPersonFactory);
      this.deleteApplicantResponse = await applicantPersonFactory.delete(workspaceUUID, applicantId, personId);
    },
    async saveApplicant(workspaceUUID: string, applicantId: number, person: any) {
      const applicantPersonFactory = $inj(ApplicantPersonFactory);
      this.saveApplicantResponse = await applicantPersonFactory.save(workspaceUUID, applicantId, person);
    },
    // Applicant Person Fatory End

    async getAllDisclosure(workspaceUUID: string, enrollmentId: number, accountId: number, applicantId: number) {
      const accountApplicantDisclosureFactory = $inj(AccountApplicantDisclosureFactory);
      this.disclosureListResponse = await accountApplicantDisclosureFactory.getAll(
        workspaceUUID,
        enrollmentId,
        accountId,
        applicantId
      );
    },

    async postTracker(workspaceUUID: string) {
      const trackerFactory = $inj(TrackerFactory);
      this.trackerResponse = await trackerFactory.post(workspaceUUID);
      const workspaceStore = $injByInterface<IWorkspaceStore>("IWorkspaceStore");
      workspaceStore.trackerUUID = this.trackerResponse.trackerUUID;
    },

    async linkTracker(applicantId: number, workspaceUUID: string, trackerUUID: string) {
      const trackerFactory = $inj(TrackerFactory);
      this.linkTrackerResponse = await trackerFactory.link(applicantId, workspaceUUID, trackerUUID);
    },

    async initTracker(
      workspaceUUID: string,
      applicantId: number | null,
      enrollmentId: number,
      workflowName: string,
      state: number
    ) {
      const trackerFactory = $inj(TrackerFactory);
      this.initTrackerResponse = await trackerFactory.init(
        workspaceUUID,
        applicantId,
        enrollmentId,
        workflowName,
        state
      );
    },

    async getApplicantAddressList(workspaceUUID: string, applicantId: number) {
      const applicantAddressFactory = $inj(ApplicantAddressFactory);
      this.applicantAddressList = await applicantAddressFactory.getApplicantAddressList(workspaceUUID, applicantId, []);
    },

    async saveApplicantAddress(workspaceUUID: string, applicantId: number, address: any) {
      const applicantAddressFactory = $inj(ApplicantAddressFactory);
      this.applicantAddressList = await applicantAddressFactory.postApplicantAddress(
        workspaceUUID,
        applicantId,
        address
      );
    },

    //Aillled Funding Factory
    async getAlliedFunding(uuid: string, enrollmentId: number, accountId: number) {
      const alliedFundingFactory = $inj(AlliedFundingFactory);
      this.alliedFundingResponse = await alliedFundingFactory.getAlliedFunding(uuid, enrollmentId, accountId);
    },

    async getAccountListAlliedFunding(uuid: string, enrollmentId: number, accountList: Array<any>) {
      const alliedFundingFactory = $inj(AlliedFundingFactory);
      this.alliedFundingResponse = await alliedFundingFactory.getAccountListAlliedFunding(
        uuid,
        enrollmentId,
        accountList
      );
    },
    async postAlliedFunding(uuid: string, enrollmentId: number, accountId: number, payment: any) {
      const alliedFundingFactory = $inj(AlliedFundingFactory);
      this.alliedFundingResponse = await alliedFundingFactory.postAlliedFunding(uuid, enrollmentId, accountId, payment);
    },

    //applicantDemographicResponse Factory
    async getApplicantDemographic(uuid: string, applicantId: number) {
      const applicantDemographicFactory = $inj(ApplicantDemographicFactory);
      this.applicantDemographicResponse = await applicantDemographicFactory.getApplicantDemographic(uuid, applicantId);
    },

    async formatObjectsFromAutocomplete(demographic: any) {
      const applicantDemographicFactory = $inj(ApplicantDemographicFactory);
      this.applicantDemographicResponse = await applicantDemographicFactory.formatObjectsFromAutocomplete(demographic);
    },

    async postApplicantDemographic(uuid: string, applicantId: number, demographic: any) {
      const applicantDemographicFactory = $inj(ApplicantDemographicFactory);
      this.applicantDemographicResponse = await applicantDemographicFactory.postApplicantDemographic(
        uuid,
        applicantId,
        demographic
      );
    },

    async putApplicantDemographic(uuid: string, applicantId: number, demographic: any) {
      const applicantDemographicFactory = $inj(ApplicantDemographicFactory);
      this.applicantDemographicResponse = await applicantDemographicFactory.putApplicantDemographic(
        uuid,
        applicantId,
        demographic
      );
    },

    async deleteApplicantDemographic(uuid: string, applicantId: number, demographic: any) {
      const applicantDemographicFactory = $inj(ApplicantDemographicFactory);
      this.applicantDemographicResponse = await applicantDemographicFactory.deleteApplicantDemographic(
        uuid,
        applicantId,
        demographic
      );
    },

    async postEnrollment(workspaceUUID: string, message: any, branchLocationId?: number) {
      const enrollmentFactory = $inj(EnrollmentFactory);
      this.enrollmentResponse = await enrollmentFactory.post(workspaceUUID, message, branchLocationId);
    },
    // Constant Factory Start
    async getConstants(typeCode: any) {
      const constantFactory = $inj(ConstantFactory);
      this.getConstantResponse = await constantFactory.get(typeCode);
    },

    async getConstProp(typeCode: any) {
      const constantFactory = $inj(ConstantFactory);
      this.getConstantResponse = await constantFactory.getProp(typeCode);
    },

    async getAllConstants() {
      const constantFactory = $inj(ConstantFactory);
      this.getAllConstantResponse = await constantFactory.getAll();
    },

    // Constant Factory End

    // Worspace State  Factory Start
    async updateWorkspaceState(stateId: number) {
      const workSpaceStateFactory = $inj(WorkspaceStateFactory);
      this.workspaceStateResponse = await workSpaceStateFactory.updateWorkspaceState(stateId);
    },

    // Workflow State  Factory Start
    async getWorkflowState(stateId: string) {
      const workflowFactory = $inj(WorkflowFactory);
      this.workflowResponse = await workflowFactory.getWorkflowState(stateId);
    },

    async getTitle() {
      const workflowFactory = $inj(WorkflowFactory);
      this.workflowResponse = await workflowFactory.getTitle();
    },
    //tracker
    async getTracker(workspaceUUID: string, trackerUUID: string) {
      const trackerFactory = $inj(TrackerFactory);
      this.trackerResponse = await trackerFactory.get(workspaceUUID, trackerUUID);
    },
    async lookupDocument() {
      const lookupFactory = $inj(LookupFactory);
      this.lookupDocumentsList = await lookupFactory.Address?.getAll();
    },

    // EnrollmentFactory Start
    async getOrPostEnrollmentFactory(workspaceUUID: string) {
      const enrollmentFactory = $inj(EnrollmentFactory);
      this.getOrPostEnrollementResponse = await enrollmentFactory.getOrPost(workspaceUUID);
    },
    async postAndNotifyEnrollmentFactory(workspaceUUID: string) {
      const enrollmentFactory = $inj(EnrollmentFactory);
      this.postAndNotifyEnrollementResponse = await enrollmentFactory.post(workspaceUUID, "Test Enrollment Factory");
    },
    async getAllEnrollmentFactory(workspaceUUID: string) {
      const enrollmentFactory = $inj(EnrollmentFactory);
      this.getAllEnrollementResponse = await enrollmentFactory.getAll(workspaceUUID);
    },
    async deleteEnrollmentFactory(workspaceUUID: string, enrollmentId: number) {
      const enrollmentFactory = $inj(EnrollmentFactory);
      this.deleteEnrollementResponse = await enrollmentFactory.delete(workspaceUUID, enrollmentId);
    },
    async postEnrollmentFactory(workspaceUUID: string, message: any, branchLocationId?: number) {
      const enrollmentFactory = $inj(EnrollmentFactory);
      this.postEnrollementResponse = await enrollmentFactory.post(workspaceUUID, message, branchLocationId);
    },
    async getEnrollemt(workspaceUUID: string, enrollmentId: number) {
      const enrollmentFactory = $inj(EnrollmentFactory);
      this.getEnrollementResponse = await enrollmentFactory.getEnrollment(workspaceUUID, enrollmentId);
    },
    async putEnrollment(uuid: string, enrollment: any) {
      const enrollmentFactory = $inj(EnrollmentFactory);
      this.putEnrollmentResponse = await enrollmentFactory.putEnrollment(uuid, enrollment);
    },
    async configureShare(uuid: string) {
      const enrollmentFactory = $inj(EnrollmentFactory);
      const enrollments = await enrollmentFactory.from(uuid);
      this.putEnrollmentResponse = await enrollmentFactory.configureShare(uuid, enrollments[0]);
    },
    async getCoreHeader() {
      const enrollmentFactory = $inj(EnrollmentFactory);
      this.getCoreHeaderResponse = await enrollmentFactory.getCoreHeader();
    },
    async getEnrollmentAccounts(workspaceUUID: string, enrollmentId: number) {
      const enrollmentFactory = $inj(EnrollmentFactory);
      this.getEnrollmentAccountsResponse = await enrollmentFactory.getEnrollmentAccounts(workspaceUUID, enrollmentId);
    },
    async getEnrollmentApplicants(workspaceUUID: string, enrollmentId: number) {
      const enrollmentFactory = $inj(EnrollmentFactory);
      this.getEnrollmentApplicantsResponse = await enrollmentFactory.getEnrollmentApplicants(
        workspaceUUID,
        enrollmentId
      );
    },
    async getEnrollmentValidation(workspaceUUID: string, enrollmentId: number) {
      const enrollmentFactory = $inj(EnrollmentFactory);
      this.getEnrollmentValidationResponse = await enrollmentFactory.getEnrollmentValidation(
        workspaceUUID,
        enrollmentId
      );
    },

    //Applicant OCR Facotry starts
    async postOCR(workspaceUUID: string, applicantId: number, message: any) {
      const applicantOCRFactory = $inj(ApplicantOCRFactory);
      this.postOcrResponse = await applicantOCRFactory.post(workspaceUUID, applicantId, message);
    },
    //Applicant OCR Facotry Ends

    //Applicant Phone Factory Start
    async getApplicantPhoneList(workspaceUUID: string, applicantId: number) {
      const applicantPhoneFactory = $inj(ApplicantPhoneFactory);
      this.postApplicantPhoneResponse = await applicantPhoneFactory.getApplicantPhoneList(workspaceUUID, applicantId);
    },

    //Applicant Phone Factory Start
    async postPhone(workspaceUUID: string, message: any) {
      const workspaceFactory = $inj(WorkspaceFactory);
      this.postWorkspaceResponse = await workspaceFactory.postWorkspace({ workspaceType: WORKFLOW_TYPES.PERSON });
      const workspaceStore = $injByInterface<IWorkspaceStore>("IWorkspaceStore");
      workspaceStore.workspaceUUID = this.postWorkspaceResponse.workspaceUUID;
    },
    //Applicant Phone Facotry Ends

    async deleteApplicantPhone(workspaceUUID: string, applicantId: number, phone: any) {
      const applicantPhoneFactory = $inj(ApplicantPhoneFactory);
      this.deleteApplicantPhoneResponse = await applicantPhoneFactory.deleteApplicantPhone(
        workspaceUUID,
        applicantId,
        phone
      );
    },

    async saveApplicantPhone(workspaceUUID: string, applicantId: number, phone: any) {
      const applicantPhoneFactory = $inj(ApplicantPhoneFactory);
      this.saveApplicantPhoneResponse = await applicantPhoneFactory.saveApplicantPhone(
        workspaceUUID,
        applicantId,
        phone
      );
    },

    async saveApplicantPhoneList(workspaceUUID: string, applicantId: number, phoneList: any) {
      const applicantPhoneFactory = $inj(ApplicantPhoneFactory);
      this.saveApplicantPhoneListResponse = await applicantPhoneFactory.saveApplicantPhoneList(
        workspaceUUID,
        applicantId,
        phoneList
      );
    },

    async putApplicantPhone(workspaceUUID: string, applicantId: number, phone: any) {
      const applicantPhoneFactory = $inj(ApplicantPhoneFactory);
      this.putApplicantPhoneResponse = await applicantPhoneFactory.putApplicantPhone(workspaceUUID, applicantId, phone);
    },

    async postApplicantPhone(workspaceUUID: string, applicantId: number, phone: any) {
      const applicantPhoneFactory = $inj(ApplicantPhoneFactory);
      this.postApplicantPhoneResponse = await applicantPhoneFactory.postApplicantPhone(
        workspaceUUID,
        applicantId,
        phone
      );
    },

    async setPhoneTypeDescription(phone: any) {
      const applicantPhoneFactory = $inj(ApplicantPhoneFactory);
      this.setPhoneTypeDescriptionResponse = await applicantPhoneFactory.setPhoneTypeDescription(phone);
    },

    async setPhoneListTypeDescription(phone: any) {
      const applicantPhoneFactory = $inj(ApplicantPhoneFactory);
      this.setPhoneListTypeDescriptionResponse = await applicantPhoneFactory.setPhoneListTypeDescription(phone);
    },

    // Applicant Disclosure Factory Start

    /* async validateEsignApplicantDisclosureFactory(uuid: string, applicantId: number, token: any) {
      const applicantDisclosureFactory = $inj(ApplicantDisclosureFactory);
      this.validateEsignApplicantDisclosureFactoryResponse = await applicantDisclosureFactory.validateEsign(
        uuid,
        applicantId,
        token
      );
      console.log(
        "Validate Esign for Applicant Disclosure Factory: ",
        this.validateEsignApplicantDisclosureFactoryResponse
      );
    },
    async requestEsignApplicantDisclosureFactory(uuid: string, applicantId: number, emailAddress: any) {
      const applicantDisclosureFactory = $inj(ApplicantDisclosureFactory);
      this.requestEsignApplicantDisclosureFactoryResponse = await applicantDisclosureFactory.requestEsign(
        uuid,
        applicantId,
        emailAddress
      );
      console.log(
        "Request Esign for Applicant Disclosure Factory: ",
        this.requestEsignApplicantDisclosureFactoryResponse
      );
    },
    async checkEsignApplicantDisclosureFactory(uuid: string, applicantId: number) {
      const applicantDisclosureFactory = $inj(ApplicantDisclosureFactory);
      this.checkEsignApplicantDisclosureFactoryResponse = await applicantDisclosureFactory.checkEsign(
        uuid,
        applicantId
      );
      
    },
    async printApplicantDisclosureFactory(uuid: string, applicantId: number, disclosures: any) {
      const applicantDisclosureFactory = $inj(ApplicantDisclosureFactory);
      this.printApplicantDisclosureFactoryResponse = await applicantDisclosureFactory.printApplicantDisclosure(
        uuid,
        applicantId,
        disclosures
      );
      
    },
    async getApplicantDisclosurePackage(workspaceUUID: string, applicantId: number) {
      const applicantDisclosureFactory = $inj(ApplicantDisclosureFactory);
      this.getApplicantDisclosurePackageResponse = await applicantDisclosureFactory.getApplicantDisclosurePackage(
        workspaceUUID,
        applicantId
      );
      
    },
    async resetApplicantDisclosure(uuid: string, applicantId: number, disclosure: any) {
      const applicantDisclosureFactory = $inj(ApplicantDisclosureFactory);
      this.resetApplicantDisclosureFactoryResponse = await applicantDisclosureFactory.resetApplicantDisclosure(
        uuid,
        applicantId,
        disclosure
      );
      
    },
    async unlockApplicantDisclosure(uuid: string, applicantId: number, disclosure: any) {
      const applicantDisclosureFactory = $inj(ApplicantDisclosureFactory);
      this.unlockApplicantDisclosureFactoryResponse = await applicantDisclosureFactory.unlockApplicantDisclosure(
        uuid,
        applicantId,
        disclosure
      );
      
    },
    async putApplicantDisclosure(uuid: string, applicantId: number, disclosure: any) {
      const applicantDisclosureFactory = $inj(ApplicantDisclosureFactory);
      this.putApplicantDisclosureFactoryResponse = await applicantDisclosureFactory.putApplicantDisclosure(
        uuid,
        applicantId,
        disclosure
      );
      
    },
    async getApplicantDisclosure(uuid: string, applicantId: number, disclosureCode: any) {
      const applicantDisclosureFactory = $inj(ApplicantDisclosureFactory);
      this.getApplicantDisclosureFactoryResponse = await applicantDisclosureFactory.getApplicantDisclosure(
        uuid,
        applicantId,
        disclosureCode
      );
      
    },
    async getAllInitialDisclosure(workspaceUUID: string, applicantId: number, requestId: number) {
      const applicantDisclosureFactory = $inj(ApplicantDisclosureFactory);
      this.getAllInitialApplicantDisclosureFactoryResponse = await applicantDisclosureFactory.getAllInitialDisclosure(
        workspaceUUID,
        applicantId,
        requestId
      );
      console.log(
        "Get All Initial Disclosure Applicant Disclosure Factory: ",
        this.getAllInitialApplicantDisclosureFactoryResponse
      );
    },

    async getAllInitialDisclosureStatus(workspaceUUID: string, applicantId: number, requestId: number) {
      const applicantDisclosureFactory = $inj(ApplicantDisclosureFactory);
      this.getAllInitialDisclosureStatusResponse = await applicantDisclosureFactory.getAllInitialDisclosureStatus(
        workspaceUUID,
        applicantId,
        requestId
      );
      console.log(
        "Get all Initial disclosure Status Applicant Disclosure Factory: ",
        this.getAllInitialDisclosureStatusResponse
      );
    },

    async postAllInitialDisclosure(workspaceUUID: string, applicantId: number) {
      const applicantDisclosureFactory = $inj(ApplicantDisclosureFactory);
      this.postAllInitialApplicantDisclosureFactoryResponse = await applicantDisclosureFactory.postAllInitialDisclosure(
        workspaceUUID,
        applicantId
      );
      console.log(
        "Post All Initial Applicant Disclosure Factory: ",
        this.postAllInitialApplicantDisclosureFactoryResponse
      );
    }, */

    // Applicant Disclosure Factory End

    // Applicant Image Factory Start
    async getAll(workspaceUUID: string, applicant: any) {
      const applicantImageFactory = $inj(ApplicantImageFactory);
      return await applicantImageFactory.getAll(workspaceUUID, applicant);
    },
    // Applicant Image Factory End

    //Applicant Document Factory Start
    async getDocuments(workspaceUUID: string, applicantId: any) {
      const applicantDocumentFactory = $inj(ApplicantDocumentFactory);
      this.applicantDocumentList = await applicantDocumentFactory.get(workspaceUUID, applicantId);
    },
    //Applicant Document Facotry Ends

    async postDocument(workspaceUUID: string, applicantId: any, file: any, document: any) {
      const applicantDocumentFactory = $inj(ApplicantDocumentFactory);
      await applicantDocumentFactory.post(workspaceUUID, applicantId, file, document);
    },

    // Applicant Suggest Factory Start
    async getApplicantSuggest(workspaceUUID: string, applicantId: number, type: any) {
      const applicantSuggestionFactory = $inj(ApplicantSuggestionFactory);
      this.getApplicantSuggestResponse = await applicantSuggestionFactory.get(workspaceUUID, applicantId, type);
    },

    // Applicant Identification Factory Start
    async getAllApplicantIdentification(workspaceUUID: string, applicantId: number, typeCodes: any) {
      const applicantIdentificationFactory = $inj(ApplicantIdentificationFactory);
      this.getApplicantIdentificationResponse = await applicantIdentificationFactory.getAll(
        workspaceUUID,
        applicantId,
        typeCodes
      );
    },

    //Applicant Email factory Start
    async getApplicantEmailList(workspaceUUID: string, applicantId: number) {
      const applicantEmailsFactory = $inj(ApplicantEmailsFactory);
      this.applicantEmailResponse = await applicantEmailsFactory.getApplicantEmailList(workspaceUUID, applicantId);
    },
    async getHomeEmail(workspaceUUID: string, applicantId: number) {
      const applicantEmailsFactory = $inj(ApplicantEmailsFactory);
      this.applicantEmailResponse = await applicantEmailsFactory.getHomeEmail(workspaceUUID, applicantId);
    },
    async putApplicantEmail(workspaceUUID: string, applicantId: number, email: any) {
      const applicantEmailsFactory = $inj(ApplicantEmailsFactory);
      this.applicantEmailResponse = await applicantEmailsFactory.putApplicantEmail(workspaceUUID, applicantId, email);
    },
    async postApplicantEmail(workspaceUUID: string, applicantId: number, email: any) {
      const applicantEmailsFactory = $inj(ApplicantEmailsFactory);
      this.applicantEmailResponse = await applicantEmailsFactory.postApplicantEmail(workspaceUUID, applicantId, email);
    },
    async deleteApplicantEmail(workspaceUUID: string, applicantId: number, email: any) {
      const applicantEmailsFactory = $inj(ApplicantEmailsFactory);
      this.applicantEmailResponse = await applicantEmailsFactory.deleteApplicantEmail(
        workspaceUUID,
        applicantId,
        email
      );
    },
    //Applicant Email factory End

    //Applicant Invite factory Start
    async inviteApplicants(workspaceUUID: string, applicantId: number, message: { inviteId: any }) {
      const applicantInviteFactory = $inj(ApplicantInviteFactory);
      this.applicantInviteResponse = await applicantInviteFactory.invite(workspaceUUID, applicantId, message);
    },
    //Applicant Invite factory End

    async getCompleteQuestionnaire(workspaceUUID: string, resource: string, resourceId: number) {
      const completeQuestionnaireFactory = $inj(CompleteQuestionnaireFactory);
      this.getCompleteQuestionnaireResponse = await completeQuestionnaireFactory.get(
        workspaceUUID,
        resource,
        resourceId
      );
    },

    // SSO Redirect  Factory Start
    async submitTokenByState(code: any, state: any, redirectUrl: any) {
      const ssoRedirectFactory = $inj(SsoRedirectFactory);
      this.applicantBusinessResponse = await ssoRedirectFactory.submitTokenByState(state, redirectUrl);
    },

    // Enrollment Account  Factory Start
    async getAccountListPrimaryApplicant(accountList: any[], applicantList: any[]) {
      const enrollmentAccountFactory = $inj(EnrollmentAccountFactory);
      this.enrollmentAccountResponse = await enrollmentAccountFactory.getAccountListPrimaryApplicant(
        accountList,
        applicantList
      );
    },

    //getEnrollmentAccountList

    async getEnrollmentAccountList(workspaceUUID: string, enrollmentId: number) {
      const enrollmentAccountFactory = $inj(EnrollmentAccountFactory);
      this.enrollmentAccountListResponse = await enrollmentAccountFactory.getEnrollmentAccountList(
        workspaceUUID,
        enrollmentId
      );
    },

    //closeEnrollmentStatus

    async closeEnrollmentStatus(workspaceUUID: string, enrollmentId: number) {
      const enrollmentStatusFactory = $inj(EnrollmentStatusFactory);
      this.enrollmentStatusResponse = await enrollmentStatusFactory.close(workspaceUUID, enrollmentId);
    },

    // PersonRelationshipsFactory Start
    async getPersonRelationships(workspaceUUID: string, applicantId: number) {
      const personRelationshipsFactory = $inj(PersonRelationshipsFactory);
      this.getPersonRelationshipsResponse = await personRelationshipsFactory.get(workspaceUUID, applicantId);
    },
    async getFunding(workspaceUUID: string, enrollmentId: number, accountId: number) {
      const enrollmentFundingFactory = $inj(EnrollmentFundingFactory);
      this.enrollmentFundingFactoryResponse = await enrollmentFundingFactory.getFunding(
        workspaceUUID,
        enrollmentId,
        accountId
      );
    },

    //getValidationEnrollment
    async getValidationEnrollment(workspaceUUID: string, enrollmentId: number) {
      const enrollmentValidationFactory = $inj(EnrollmentValidationFactory);
      this.enrollmentValidationResponse = await enrollmentValidationFactory.getValidation(
        workspaceUUID,
        enrollmentId,
        "ALL"
      );
    },

    async oAuthGet(params?: any) {
      const oauthFactory = $inj(OAuthFactory);
      this.oauthResponse = await oauthFactory.getAll(params);
    },

    //Questionnaire
    async getAllQuestionnaire(
      workspaceUUID: string,
      resource: string,
      resourceId: number,
      queryParams?: Record<string, string>
    ) {
      const questionnaireFactory = $inj(QuestionnaireFactory);
      this.questionnaireSaveResponse = await questionnaireFactory.getAll(
        workspaceUUID,
        resource,
        resourceId,
        queryParams as any
      );
    },

    async questionnaireSave(
      workspaceUUID: string,
      resource: string,
      resourceId: number,
      questionnaireId: number,
      question: Record<string, unknown>,
      queryParams?: Record<string, string>
    ) {
      const questionnaireFactory = $inj(QuestionnaireFactory);
      this.questionnaireSaveResponse = await questionnaireFactory.save(
        workspaceUUID,
        resource,
        resourceId,
        questionnaireId,
        question,
        queryParams
      );
    }
  }
});

interface CommonStoreState {
  getWorkspaceResponse: any;
  postWorkspaceResponse: any;
  setWorkspaceResponse: any;
  isClosedResponse: any;
  applicantBusinessResponse: any;
  applicantResponse: any;
  setApplicantResponse: any;
  getApplicantResponse: any;
  putApplicantResponse: any;
  postApplicantResponse: any;
  deleteApplicantResponse: any;
  saveApplicantResponse: any;
  createApplicantResponse: any;
  activeApplicantId: number;
  disclosureListResponse: any;
  trackerResponse: any;
  linkTrackerResponse: any;
  initTrackerResponse: any;
  alliedFundingResponse: any;
  applicantDemographicResponse: any;
  ABAresponse: any;
  uiImageResponse: any;
  applicantAddressList: any;
  enrollmentResponse: any;
  getConstantResponse: any;
  getAllConstantResponse: any;
  workspaceStateResponse: any;
  workflowResponse: any;
  lookupDocumentsList: any;
  getOrPostEnrollementResponse: any;
  postAndNotifyEnrollementResponse: any;
  getAllEnrollementResponse: any;
  deleteEnrollementResponse: any;
  postEnrollementResponse: any;
  getEnrollementResponse: any;
  putEnrollmentResponse: any;
  getCoreHeaderResponse: any;
  getEnrollmentAccountsResponse: any;
  getEnrollmentApplicantsResponse: any;
  getEnrollmentValidationResponse: any;
  postApplicantPhoneResponse: any;
  deleteApplicantPhoneResponse: any;
  saveApplicantPhoneResponse: any;
  saveApplicantPhoneListResponse: any;
  putApplicantPhoneResponse: any;
  postApplicantPhoneListResponse: any;
  setPhoneTypeDescriptionResponse: any;
  setPhoneListTypeDescriptionResponse: any;
  postApplicantOCRResponse: any;
  validateEsignApplicantDisclosureFactoryResponse: any;
  requestEsignApplicantDisclosureFactoryResponse: any;
  checkEsignApplicantDisclosureFactoryResponse: any;
  printApplicantDisclosureFactoryResponse: any;
  postAllInitialApplicantDisclosureFactoryResponse: any;
  getAllInitialDisclosureStatusApplicantDisclosureFactoryResponse: any;
  getAllInitialDisclosureStatusResponse: any;
  getAllInitialApplicantDisclosureFactoryResponse: any;
  getApplicantDisclosureFactoryResponse: any;
  putApplicantDisclosureFactoryResponse: any;
  unlockApplicantDisclosureFactoryResponse: any;
  resetApplicantDisclosureFactoryResponse: any;
  getApplicantDisclosurePackageResponse: any;
  getApplicantSuggestResponse: any;
  getApplicantIdentificationResponse: any;
  applicantEmailResponse: any;
  applicantDocumentList: any;
  postOcrResponse: any;
  applicantInviteResponse: any;
  getCompleteQuestionnaireResponse: any;
  ssoRedirectResponse: any;
  enrollmentAccountResponse: any;
  enrollmentAccountListResponse: any;
  enrollmentStatusResponse: any;
  getPersonRelationshipsResponse: any;
  enrollmentFundingFactoryResponse: any;
  enrollmentValidationResponse: any;
  oauthResponse: any;
  questionnaireSaveResponse: any;
}
