import {defineStore, type Store} from "pinia";
import type {IDisclosureStore} from "@/common/services/Disclosure/IDisclosureStore";

type DisclosureData = {
    tagName: string,
    tagType: string,
    value: any,
    editable: boolean,
    required: boolean,
    requiredMessage?: string
}

type Signature = {
    applicantId: number,
    disclosureCode: string,
    signed: boolean,
    originId: number,
    tagName: string
}

export const DisclosureStore = defineStore({
    id: "DisclosureStore",
    state: (): IDisclosureStore => <IDisclosureStore>({
        disclosureDictionary: {data: {}} as { data: DisclosureData },
        signatures: [] as Array<Signature>
    }),
    actions: {
        clickSignButton(key: string, value: string) {}
    }
});