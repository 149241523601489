import { inject, injectable } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";

@injectable()
class EnrollmentCipFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  getCipServicesForApplicant(workspaceUUID: string, enrollmentId: number, applicantId: number) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/applicant/${applicantId}/cip`,
      {},
      ResponseTypes.Payload
    );
  }

  postCipServiceRequest(workspaceUUID: string, enrollmentId: number, cipId: number, serviceCode: string) {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/cip/${cipId}/service/${serviceCode}`,
      {},
      {},
      ResponseTypes.Payload
    );
  }

  async putCipServiceRequest(
    workspaceUUID: string,
    enrollmentId: number,
    cipId: number,
    serviceCode: string,
    message: any
  ) {
    return await this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/cip/${cipId}/service/${serviceCode}`,
      message,
      {},
      ResponseTypes.Payload
    );
  }
}
export default EnrollmentCipFactory;
