import type AxiosResponseInterceptor from '@/common/interfaces/axios/AxiosResponseInterceptor'
import type {AxiosError} from 'axios'
import router from '@/open/router'
import {WorkflowStatesConstant} from '@/common/constant/WorkflowStatesConstant'
import {$injByInterface} from '@/common/decorators/depinject'
import type IRouterService from '@/common/services/utils/IRouterService'

const redirectErrors = new Set([
  'OPEN_NOT_AUTHORIZED',
  'OPEN_API_TOKEN_EXPIRED'
])

const hardErrors = new Set([
  'JXCHANGE_CORE_EOD_PROCESSING_ERROR',
  'ABORT_PROCESSING_APPLICATION',
])

export default class ApplicantChannelResponseInterceptors implements AxiosResponseInterceptor {
  onFulfilled<T, V>(response: V): Promise<T> | T {
    return response as unknown as T
  }

  onRejected(error: AxiosError): Promise<AxiosError> {
    function containsRedirectError(axiosError: AxiosError) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res = axiosError.response as any

      const errors = res?.data?.errors

      if (!Array.isArray(errors)) {
        return false
      }

      return (
        Array.isArray(errors) &&
        errors.some((it) => {
          const hasCode = !!it.code && typeof it.code === 'string'

          return hasCode && redirectErrors.has(it.code)
        })
      )
    }

    if (containsRedirectError(error)) {

      const routerService = $injByInterface<IRouterService>("IRouterService");

      const workspaceUUID = router?.currentRoute?.value?.params['workspaceUUID'] as string
      const trackerUUID = router?.currentRoute?.value?.query['trackerUUID'] as string

      if(!workspaceUUID) {
        console.error("Error: attempted to redirect to continuation screen, but the router did not have a workspaceUUID...")
      }

      if(!trackerUUID) {
        console.error("Error: attempted to redirect to continuation screen, but the router did not have a trackerUUID...")
      }

      if(workspaceUUID && trackerUUID) {
        routerService.push({
          name: WorkflowStatesConstant.CONTINUE.STATE,
          params: { workspaceUUID },
          query: { trackerUUID },
          replace: true
        });
      }

    }

    return Promise.reject(error)
  }
}
