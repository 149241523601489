import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { $inj } from "@/common/decorators/depinject";
import { WorkflowStatesConstant } from "@/common/constant/WorkflowStatesConstant";
import { DisplayConstant } from "@/common/constant/DisplayConstant";
import { isUndefined } from "lodash";
import { ErrorFactory } from "@/common/services/services.module";

class WorkflowRouteGuard {
  public static async HandleUnwantedChildNavigation(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    /**these condition is to ensure routing to workflow state and not to divert to children routes.
     * route path are empty string. but that doesn't mean automatic navigation should be triggered.
     * every navigation to a route path should be controlled by our program.
     */
    if (to.name !== WorkflowStatesConstant.WORKFLOW.STATE) {
      to.name = WorkflowStatesConstant.WORKFLOW.STATE;
      next(to);
    } else {
      if (isUndefined(to.query.trackerUUID)) {
        WorkflowRouteGuard.displayError();

        to.name = WorkflowStatesConstant.ERROR.STATE;
        next(to);
      } else {
        next();
      }
    }
  }

  public static async HandleNoPropsNavigation(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    if (isUndefined(to.query.trackerUUID)) {
      WorkflowRouteGuard.displayError();

      to.name = WorkflowStatesConstant.ERROR.STATE;
      next(to);
    } else {
      next();
    }
  }

  public static displayError() {
    const errorFactory = $inj(ErrorFactory);
    errorFactory.setErrorMessage({
      data: { message: ''}
    });
  }
}
export default WorkflowRouteGuard;
