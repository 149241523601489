import { inject, injectable } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import type WorkspacePromoCode from "@/common/data/WorkspacePromoCode";

@injectable()
export default class WorkspacePromoCodeService {

  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) { }

  createPromoCode(workspaceUUID: string, promo: WorkspacePromoCode): Promise<WorkspacePromoCode> {
   return this.httpHandler.post(`{{boltsbranch}}/workspace/${workspaceUUID}/promo-code`, promo)
  }

}