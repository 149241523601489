import { inject, injectable } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import type WorkspaceReferral from "@/common/data/WorkspaceReferral";

@injectable()
export default class WorkspaceReferralService {

  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) { }

  createRef(workspaceUUID: string, ref: WorkspaceReferral): Promise<WorkspaceReferral> {
    return this.httpHandler.post(`{{boltsbranch}}/workspace/${workspaceUUID}/referral`, ref)
  }

}