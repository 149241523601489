import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";

@injectable()
class RuleDecisionFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  get(workspaceUUID: string, enrollmentId: number, ruleUUID: number) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/rule?ruleUUID=${ruleUUID}`,
      {},
      ResponseTypes.Payload
    );
  }
}

export default RuleDecisionFactory;
