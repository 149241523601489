<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "bltScreen",
  props: {
    contentHeightFix: {
      type: Boolean,
      default: () => false
    },
    loading:{
      type:Boolean,
      default:() =>false
    }
  }
});
</script>
<template>
  <section class="blt-screen">
    <div class="container">
      <div class="content" :class="{ fixcontent: contentHeightFix }">
        <slot name="content"  v-if="!loading">
          <!-- all the blt-form content goes here -->
        </slot>
      </div>
      <div class="footer-content">
        <slot name="footer"></slot>
      </div>
    </div>
  </section>
</template>
<style scoped>
.blt-screen {
  display: block;
  overflow: hidden;
}
.container {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.content {
  flex: 1;
}

.footer {
  flex: 0;
}

@media (max-width: 480px) {

  .divider {
    width: 107%;
  }
}















</style>
